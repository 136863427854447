//@import "~antd/dist/antd.css";
@import './variables';

:root {
}

body {
  --colorPrimaryText: #fff;
  --p-colorPrimaryHover: #0b81fa;
  --p-colorPrimaryActive: #228cfa;
  --p-colorPrimaryAlpha20: hsla(210, 96%, 45%, 25%);
  --p-colorPrimaryAlpha40: hsla(210, 96%, 45%, 40%);
  --p-colorPrimaryAlpha50: hsla(210, 96%, 45%, 50%);
  --p-colorPrimaryDisabled: #d9d9d9;
  --p-colorPrimaryDisabledText: #8d8d8d;
  --colorBackgroundText: #30313d;
  --p-colorBackgroundDivider: #f2f2f2;
  --p-colorBackgroundDisabled: #f2f2f2;
  --p-colorBackgroundDisabledDeemphasize05: #e6e6e6;
  --p-colorBackgroundDeemphasize03: #f7f7f7;
  --p-colorBackgroundDeemphasize05: #f2f2f2;
  --p-colorBackgroundDeemphasize10: #e6e6e6;
  --p-colorBackgroundDeemphasize15: #d9d9d9;
  --p-colorBackgroundDeemphasize20: #cccccc;
  --p-colorBackgroundLightenAbsolute05: #ffffff;
  --p-colorBackgroundContrastAlpha05: rgba(0, 0, 0, 0.05);
  --p-colorBackgroundContrastAlpha08: rgba(0, 0, 0, 0.08);
  --p-colorBackgroundContrastAlpha30: rgba(0, 0, 0, 0.3);
  --colorTextSecondary: #6d6e78;
  --colorTextPlaceholder: #757680;
  --colorSuccessText: #fff;
  --colorDangerText: #fff;
  --colorWarningText: #30313d;
  --p-linkProtectionsBadgeBackground: #1d3944;
  --p-linkProtectionsBadgeColor: #fff;
  --fontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --fontSmooth: always;
  --fontVariantLigatures: normal;
  --fontVariationSettings: normal;
  --fontLineHeight: 1.45;
  --fontSizeBase: 1rem;
  --fontSizeSm: 0.93rem;
  --fontSizeXs: 0.875rem;
  --fontSize2Xs: 0.8125rem;
  --fontSize3Xs: 0.75rem;
  --fontSizeLg: 1.0625rem;
  --fontSizeXl: 1.125rem;
  --fontSize2Xl: 1.25rem;
  --fontWeightLight: 300;
  --fontWeightNormal: 400;
  --fontWeightMedium: 600;
  --fontWeightBold: bold;
  --colorPrimary: #0570de;
  --colorBackground: #fff;
  --colorText: #30313d;
  --colorSuccess: #30b130;
  --colorDanger: #df1b41;
  --colorWarning: #f6e6b9;
  --colorIcon: var(--colorTextSecondary);
  --colorIconHover: var(--colorText);
  --colorIconCheckmark: var(--colorPrimaryText);
  --colorIconCardError: var(--colorDanger);
  --colorIconCardCvc: var(--colorIcon);
  --colorIconCardCvcError: var(--colorDanger);
  --colorIconChevronDown: var(--colorIcon);
  --colorIconChevronDownHover: var(--colorIconHover);
  --colorIconClose: var(--colorIcon);
  --colorIconCloseHover: var(--colorIconHover);
  --colorIconLoadingIndicator: var(--p-colorBackgroundContrastAlpha30);
  --colorIconMenu: var(--colorPrimary);
  --colorIconMenuHover: var(--colorIconMenu);
  --colorIconMenuOpen: var(--colorIconMenu);
  --colorIconPasscodeDevice: var(--colorIcon);
  --colorIconPasscodeDeviceHover: var(--colorIconHover);
  --colorIconPasscodeDeviceNotification: var(--colorPrimary);
  --colorIconRedirect: currentColor;
  --colorIconTab: var(--colorIcon);
  --colorIconTabHover: var(--colorText);
  --colorIconTabSelected: var(--colorPrimary);
  --colorIconTabMore: var(--colorIcon);
  --colorIconTabMoreHover: var(--colorText);
  --spacingUnit: 0.25rem;
  --spacingGridRow: var(--p-spacing3);
  --spacingGridColumn: var(--p-spacing3);
  --spacingTab: var(--p-spacing1);
  --spacingPickerItem: var(--p-spacing2);
  --spacingAccordionItem: var(--p-spacing2);
  --borderRadius: 5px;
  --focusBoxShadow: 0 0 0 3px var(--p-colorPrimaryAlpha20), 0 1px 1px 0 var(--p-colorBackgroundContrastAlpha08);
  --focusOutline: 0px;
  --p-spacingXs: 1px;
  --p-spacingSm: 2px;
  --p-spacing1: 0.25rem;
  --p-spacing2: 0.5rem;
  --p-spacing3: 0.75rem;
  --p-spacing4: 1rem;
  --p-spacing5: 1.25rem;
  --p-spacing6: 1.5rem;
  --p-spacing7: 1.75rem;
  --p-spacing8: 2rem;
  --p-spacing9: 2.25rem;
  --p-spacing10: 2.5rem;
  --p-logoLightDisplay: block;
  --p-logoDarkDisplay: none;
  --p-logoTabLightDisplay: block;
  --p-logoTabDarkDisplay: none;
  --p-logoTabSelectedLightDisplay: block;
  --p-logoTabSelectedDarkDisplay: none;
  --p-logoBlockLightDisplay: block;
  --p-logoBlockDarkDisplay: none;
  -webkit-font-smoothing: antialiased;

  [data-theme=light] & {

    }

  [data-theme=dark] & {
    ::-webkit-scrollbar {
      width            : 10px;
      height           : 10px;
      background-color : #202020;
      &:active {
        background-color : #101010
        }
      }
    ::-webkit-scrollbar-track {
      margin           : 5px 5px;
      border-radius    : 5px;
      background-color : #202020;
      }
    ::-webkit-scrollbar-thumb {
      border-radius    : 5px;
      background-color : #383838;
      }
    }
  }

body, html {
  overflow: hidden;
  margin: 0;
}

.sb-icon {
  svg {
    top: .125em;
    position: relative;
    fill: currentColor;
  }
}

.sb-badges-box {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.sb-info-badge {
  height: 30px;
  border-radius: 50%;
  color: #aaa;
  margin: 0 2px;
  overflow: hidden;
  text-align: center;
  border: 1px solid #f1f1f1;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.on {
    background: rgb(133 203 255);
    border: 1px solid transparent;
    color: #fff;
  }
}

.ant-menu-inline-collapsed-tooltip {
  .anticon {
    display: inline !important;
  }
}

@media screen and (max-width: $screen-xs-max) {
  .hidden-xs {
    display: none !important;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .hidden-sm {
    display: none !important;
  }
}

@media screen and (max-width: $screen-md-max) {
  .hidden-md {
    display: none !important;
  }
}

@media screen and (max-width: $screen-lg-max) {
  .hidden-lg {
    display: none !important;
  }
}

@media screen and (max-width: $screen-xl-max) {
  .hidden-xl {
    display: none !important;
  }
}

@media screen and (min-width: $screen-xxl-min) {
  .hidden-xxl {
    display: none !important;
  }
}

.ant-menu-inline-collapsed-tooltip {
  .ant-tooltip-inner {
    a {
      color: #fff;
    }
  }
}